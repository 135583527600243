import React, { useEffect, useState } from "react";
import MetaData from "../../Layouts/MetaData";
import { useDispatch, useSelector } from "react-redux";
import {
  createCarousel,
  getCarousel,
  deleteCarousel,
} from "../../../actions/carouselAction";
import { useSnackbar } from "notistack";
import BackdropLoader from "../../Layouts/BackdropLoader";
import ConfirmDelete from "../../../utils/confirm-delete";

const Carousel = () => {
  const [images, setImages] = useState([]);
  const [imagesPreview, setImagesPreview] = useState([]);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const { loading, carousels } = useSelector((state) => state.carousels);

  const handletImageChange = (e) => {
    const files = Array.from(e.target.files);

    setImages([]);
    setImagesPreview([]);

    files.forEach((file) => {
      const reader = new FileReader();

      reader.onload = () => {
        if (reader.readyState === 2) {
          setImagesPreview((oldImages) => [
            ...oldImages,
            { url: reader.result, name: file.name },
          ]);
          setImages((oldImages) => [
            ...oldImages,
            { url: reader.result, name: file.name },
          ]);
        }
      };
      reader.readAsDataURL(file);
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!images) {
      enqueueSnackbar("Add Carousel Images", { variant: "error" });
      return;
    }

    dispatch(createCarousel({ images }));

    setTimeout(() => {
      dispatch(getCarousel());
    }, 2000);

    setImages([]);
    setImagesPreview([]);
  };

  const handleDelete = (id) => {
    dispatch(deleteCarousel(id));
  };

  useEffect(() => {
    dispatch(getCarousel());
  }, [dispatch]);

  return (
    <>
      <MetaData title="Admin Products | Power Mart" />

      {loading && <BackdropLoader />}

      <div className="flex justify-start items-center">
        <h1 className="text-lg font-medium uppercase">Carousel</h1>
      </div>
      <form
        onSubmit={handleSubmit}
        encType="multipart/form-data"
        className="flex flex-col bg-white rounded-lg shadow p-4"
        id="mainform"
      >
        <div className="flex gap-2 overflow-x-auto h-32 border rounded">
          {imagesPreview.map(({ url, name }, i) => (
            <img
              draggable="false"
              src={url}
              alt={name}
              key={i}
              className="w-full h-full object-contain"
            />
          ))}
        </div>
        <label className="rounded font-medium bg-gray-400 text-center cursor-pointer text-white p-2 shadow hover:shadow-lg my-2">
          <input
            type="file"
            name="images"
            accept="image/*"
            multiple
            onChange={handletImageChange}
            className="hidden"
          />
          Choose Files
        </label>
        <div className="flex justify-end">
          <input
            form="mainform"
            type="submit"
            className="bg-primary-orange uppercase w-1/3 p-3 text-white font-medium rounded shadow hover:shadow-lg cursor-pointer"
            value="Submit"
            disabled={images.length === 0}
          />
        </div>
      </form>
      <div className="flex flex-col space-y-5 p-4">
        <h1 className="text-2xl font-semibold">Saved Banner</h1>
        {carousels.map(({ image, name, _id }, i) => (
          <div key={i} className="flex justify-start gap-3 items-center">
            <img src={image.url} alt={name} className="aspect-video w-36" />
            <button
              className="bg-red-500 text-white py-2 px-3 rounded-md"
              onClick={() => ConfirmDelete(_id, handleDelete)}
            >
              Delete
            </button>
          </div>
        ))}
      </div>
    </>
  );
};

export default Carousel;
