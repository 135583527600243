import SearchIcon from '@mui/icons-material/Search';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Searchbar = () => {

    const [keyword, setKeyword] = useState("");
    const navigate = useNavigate();
    const [isSearchOpen, setIsSearchOpen] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        if(keyword.trim()){
            navigate(`/products/${keyword}`)
        } else {
            navigate('/products');
        }
    }

    return (
        // <form onSubmit={handleSubmit} className="w-full sm:w-9/12 px-1 sm:px-4 py-1.5 flex justify-between items-center shadow-md bg-white rounded-sm overflow-hidden">
        //     <input value={keyword} onChange={(e) => setKeyword(e.target.value)} className="text-sm flex-1 outline-none border-none placeholder-gray-500" type="text" placeholder="Search for products, brands and more" />
        //     <button type="submit" className="text-primary-blue"><SearchIcon /></button>
        // </form>

            <form
                onSubmit={handleSubmit}
                className={`w-full sm:w-9/12 px-1 sm:px-4 py-1.5 flex justify-between items-center rounded-sm overflow-hidden transition-all
                    ${isSearchOpen ? 'block' : 'flex'}
                    bg-transparent sm:bg-white shadow-none sm:shadow-md`}
            >
                {/* Input field - hidden on mobile screens, shown on larger screens */}
                <input
                    value={keyword}
                    onChange={(e) => setKeyword(e.target.value)}
                    className={`text-sm flex-1 outline-none border-none placeholder-gray-500 transition-all
                        ${isSearchOpen ? 'block' : 'hidden'} sm:block`}
                    type="text"
                    placeholder="Search for products, brands and more"
                />
                {/* Search button */}
                <button
                    type="submit"
                    className="text-black outline-none border-none bg-transparent"
                    onClick={() => setIsSearchOpen(!isSearchOpen)} // Toggle input visibility on mobile screens
                >
                    <SearchIcon className="text-white sm:text-primary-blue" />

                </button>
            </form>
    );
};

export default Searchbar;
