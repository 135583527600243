import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import { SnackbarProvider } from "notistack";
import { GoogleOAuthProvider } from "@react-oauth/google";

import "./index.css";
import "react-confirm-alert/src/react-confirm-alert.css";

import App from "./App";
import store from "./store";
import axios from "axios";

const CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
// axios.defaults.baseURL = 'http://192.168.1.16:4000';
ReactDOM.render(
  // <React.StrictMode>
  <Provider store={store}>
    <GoogleOAuthProvider clientId={CLIENT_ID}>
      <SnackbarProvider
        maxSnack={2}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Router>
          <App />
        </Router>
      </SnackbarProvider>
    </GoogleOAuthProvider>
  </Provider>,
  // </React.StrictMode>,
  document.getElementById("root")
);
