export const ALL_Carousel_REQUEST = "ALL_Carousel_REQUEST";
export const ALL_Carousel_SUCCESS = "ALL_Carousel_SUCCESS";
export const ALL_Carousel_FAIL = "ALL_Carousel_FAIL";



export const NEW_Carousel_REQUEST = "NEW_Carousel_REQUEST";
export const NEW_Carousel_SUCCESS = "NEW_Carousel_SUCCESS";
export const NEW_Carousel_RESET = "NEW_Carousel_RESET";
export const NEW_Carousel_FAIL = "NEW_Carousel_FAIL";

export const DELETE_Carousel_REQUEST = "DELETE_Carousel_REQUEST";
export const DELETE_Carousel_SUCCESS = "DELETE_Carousel_SUCCESS";
export const DELETE_Carousel_RESET = "DELETE_Carousel_RESET";
export const DELETE_Carousel_FAIL = "DELETE_Carousel_FAIL";





export const CLEAR_ERRORS = "CLEAR_ERRORS";