import { confirmAlert } from "react-confirm-alert";
const ConfirmDelete = (id,handleDelete) => {
    console.log('confirm delete')
    confirmAlert({
        title: "Confirm to delete",
        message:
            "Are you sure to delete this?",
        buttons: [
            {
                label: "Yes",
                onClick: () => handleDelete(id),
            },
            {
                label: "No",
            },
        ],
    });
};

export default ConfirmDelete