import React, { forwardRef, Fragment, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { useSnackbar } from "notistack";
import moment from "moment";

const PrintToPdf = forwardRef(({ data }, ref) => {
  const styles = {
    container: {
      backgroundColor: "#ffffff",
      border: "1px solid #e0e0e0",
      padding: "20px",
      borderRadius: "10px",
      boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
      margin: "20px",
      minHeight: "29cm",
    },
    textCenter: {
      textAlign: "center",
    },
    textLeft: {
      textAlign: "left",
    },
    textRight: {
      textAlign: "right",
    },
    textGray600: {
      color: "#6b7280",
    },
    textGray800: {
      color: "#374151",
    },
    bgGray100: {
      backgroundColor: "#f3f4f6",
    },
    shadowSm: {
      boxShadow: "0 1px 2px rgba(0, 0, 0, 0.05)",
    },
    shadowLg: {
      boxShadow: "0 10px 15px rgba(0, 0, 0, 0.1)",
    },
    roundedLg: {
      borderRadius: "10px",
    },
    borderBottomDotted: {
      borderBottom: "1px dotted #ddd",
    },
    borderBottomSolid: {
      borderBottom: "1px solid #ddd",
    },
    borderTopDotted: {
      borderTop: "1px dotted #ddd",
    },
    table: {
      width: "100%",
      marginTop: "10px",
    },
    tableCell: {
      padding: "5px",
      borderBottom: "1px dotted #ddd",
    },
    hr: {
      border: 0,
      borderTop: "1px solid #e0e0e0",
      margin: "10px 0",
    },
    button: {
      backgroundColor: "#1d4ed8",
      color: "#ffffff",
      fontWeight: "bold",
      padding: "10px 20px",
      borderRadius: "5px",
      cursor: "pointer",
      border: "none",
    },
  };

  const formatAddress = (address, city, state, pincode) => {
    let formattedAddress = address ? address : "";
    if (city) formattedAddress += `, ${city}`;
    if (state) formattedAddress += `, ${state}`;
    if (pincode) formattedAddress += `, ${pincode}`;
    return formattedAddress;
  };

  return (
    <Fragment>
      <div ref={ref} style={styles.container}>
        <div style={styles.textCenter}>
          <h1 style={{ fontSize: "24px", fontWeight: "bold" }}>Power Mart</h1>
          <p style={styles.textGray600}>Email: live.powermart@gmail.com</p>
          <p style={styles.textGray600}>Website: https://powermart.pk</p>
        </div>

        <div style={{ ...styles.textCenter, margin: "20px 0" }}>
          <hr style={styles.hr} />
          <h3 style={{ fontSize: "18px", fontWeight: "bold", margin: "10px 0" }}>PACKING SLIP</h3>
          <hr style={styles.hr} />
        </div>

        <div style={{ ...styles.textCenter, marginBottom: "20px" }}>
          <div style={{ display: "flex", justifyContent: "center", fontSize: "18px" }}>
            <p style={{ ...styles.textGray800, padding: "10px", fontWeight: "bold" }}>Tracking Number:</p>
            <p style={{ ...styles.textGray800, padding: "10px" }}>{data?.courierDetail?.trackingId}</p>
          </div>
        </div>

        <div style={{ ...styles.bgGray100, padding: "10px", marginBottom: "20px", ...styles.roundedLg, ...styles.shadowSm }}>
          <h1 style={{ fontSize: "20px", fontWeight: "bold", borderRight: "1px solid #ddd", paddingRight: "10px" }}>Shipping Address</h1>
          <table style={styles.table}>
            <tbody>
              <tr>
                <td style={{ ...styles.textGray800, padding: "10px", fontWeight: "bold" }}>Client Name</td>
                <td style={{ ...styles.textGray800, padding: "10px" }}>{data.user.name}</td>
              </tr>
              <tr>
                <td style={{ ...styles.textGray800, padding: "10px", fontWeight: "bold" }}>Address</td>
                <td style={{ ...styles.textGray800, padding: "10px" }}>{formatAddress(data?.shippingInfo?.address, data?.shippingInfo?.city, data?.shippingInfo?.state, data?.shippingInfo?.pincode)}</td>
              </tr>
              <tr>
                <td style={{ ...styles.textGray800, padding: "10px", fontWeight: "bold" }}>Contact No</td>
                <td style={{ ...styles.textGray800, padding: "10px" }}>{data?.shippingInfo?.phoneNo}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div style={{ marginBottom: "20px" }}>
          <div style={{ display: "flex", justifyContent: "start" }}>
            <p style={{ ...styles.textGray800, padding: "10px", fontWeight: "bold" }}>Invoice Date:</p>
            <p style={{ ...styles.textGray800, padding: "10px" }}>{moment(data?.create_at).format("YYYY-MM-DD")}</p>
          </div>
          <div style={{ display: "flex", justifyContent: "start" }}>
            <p style={{ ...styles.textGray800, padding: "10px", fontWeight: "bold" }}>Invoice No:</p>
            <p style={{ ...styles.textGray800, padding: "10px", fontSize: "20px" }}>{data?._id}</p>
          </div>
        </div>

        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <table style={{ ...styles.bgGray100, ...styles.roundedLg, ...styles.shadowSm, width: "50%" }}>
            <tbody>
              {[
                { label: "Total Amount", value: `Rs. ${data?.totalPrice}` },
                { label: "Payment Method", value: data?.paymentMethod },
              ].map((item, index) => (
                <tr key={index}>
                  <td style={{ ...styles.textGray800, padding: "10px", fontWeight: "bold" }}>{item.label}</td>
                  <td style={{ ...styles.textGray800, padding: "10px" }}>{item.value}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div style={{ marginTop: "40px" }}>
          <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "20px" }}>
            <div style={{ width: "30%", textAlign: "center" }}>
              <hr />
              <p style={{ padding: "10px" }}>Received By</p>
            </div>
            <div style={{ width: "30%", textAlign: "center" }}>
              <hr />
              <p style={{ padding: "10px" }}>Authorized By</p>
            </div>
          </div>
          <div style={styles.textCenter}>
            <hr />
          </div>
        </div>
      </div>
    </Fragment>
  );
});

PrintToPdf.displayName = "printToPdf";

const PackingSlip = ({ data }) => {
  const { enqueueSnackbar } = useSnackbar();
  const componentRef = useRef();
  const handlePrint = useReactToPrint({

    

    content: () => componentRef.current,
  });

  function handleButtonClick(){
    if(data.orderStatus === 'Processing'){
        enqueueSnackbar("Please add Shipping Details to Print Packing Slip!", {
        variant: "Warning",
      });
      return;
    }
    handlePrint()
  }

  return (
    <div>
      <div style={{ display: "none" }}>
        <PrintToPdf ref={componentRef} data={data} />
      </div>
      <button
        id="ntb"
        onClick={handleButtonClick}
        style={{
          backgroundColor: "#1d4ed8",
          color: "#ffffff",
          fontWeight: "bold",
          padding: "10px 20px",
          borderRadius: "5px",
          cursor: "pointer",
          border: "none",
        }}
      >
        Print Packing Slip
      </button>
    </div>
  );
};

export default PackingSlip;
