import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  clearErrors,
  getOrderDetails,
  updateOrder,
} from "../../actions/orderAction";
import { UPDATE_ORDER_RESET } from "../../constants/orderConstants";
import { formatDate } from "../../utils/functions";
import TrackStepper from "../Order/TrackStepper";
import Loading from "./Loading";
import { Link } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import MetaData from "../Layouts/MetaData";
// import './UpdateOrder.css'
import OrderDetail from "./OrderDetail";
import TextField from "@mui/material/TextField";

const courierCompany = ["TCS", "Pakistan Post", "Leopards", "DHL", "Blue Ex"];

const UpdateOrder = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams();

  const [courierDetail, setCourierDetail] = useState({
    trackingId: "",
    companyName: "",
  });

  const { order, error, loading } = useSelector((state) => state.orderDetails);
  const { isUpdated, error: updateError } = useSelector((state) => state.order);
  const [status, setStatus] = useState("");

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error, { variant: "error" });
      dispatch(clearErrors());
    }
    if (updateError) {
      enqueueSnackbar(updateError, { variant: "error" });
      dispatch(clearErrors());
    }
    if (isUpdated) {
      enqueueSnackbar("Order Updates Successfully", { variant: "success" });
      dispatch({ type: UPDATE_ORDER_RESET });
    }
    dispatch(getOrderDetails(params.id));
  }, [dispatch, error, params.id, isUpdated, updateError, enqueueSnackbar]);

  const updateOrderSubmitHandler = (e) => {
    e.preventDefault();
    const formData = new FormData();
    if (status === "") {
      enqueueSnackbar("Please Select the Status first!", {
        variant: "Error",
      });
      return;
    }
    if (courierDetail.companyName === "") {
      enqueueSnackbar("Please Select the Courier Company!", {
        variant: "Error",
      });
      return;
    }
    if (courierDetail.trackingId === "") {
      enqueueSnackbar("Please add Tracking Id!", {
        variant: "Error",
      });
      return;
    }
    formData.set("status", status);
    formData.set("trackingId", courierDetail.trackingId);
    formData.set("companyName", courierDetail.companyName);
    dispatch(updateOrder(params.id, formData));
  };

  return (
    <>
      <MetaData title="Admin: Update Order | Power Mart" />

      {loading ? (
        <Loading />
      ) : (
        <>
          {order && order.user && order.shippingInfo && (
            <div className="flex flex-col gap-4">
              <Link
                to="/admin/orders"
                className="ml-1 flex items-center gap-0 font-medium text-primary-blue uppercase"
              >
                <ArrowBackIosIcon sx={{ fontSize: "18px" }} />
                Go Back
              </Link>

              <div className="flex flex-col sm:flex-row bg-white shadow-lg rounded-lg min-w-full">
                <div className="sm:w-1/2 border-r">
                  <OrderDetail order={order} />
                </div>

                <form
                  onSubmit={updateOrderSubmitHandler}
                  className="flex flex-col gap-3 p-8"
                >
                  <h3 className="font-medium text-lg">Update Status</h3>
                  {/* <div className="flex gap-2">
                    <p className="text-sm font-medium">Current Status:</p>
                    <p className="text-sm">
                      {order.orderStatus === "Shipped" &&
                        `Shipped on ${formatDate(order.shippedAt)}`}
                      {order.orderStatus === "Processing" &&
                        `Ordered on ${formatDate(order.createdAt)}`}
                      {order.orderStatus === "Delivered" &&
                        `Delivered on ${formatDate(order.deliveredAt)}`}
                    </p>
                  </div> */}

                  <div className="flex gap-2">
                    <p className="text-sm font-medium">Current Status:</p>
                    <p className="text-sm">
                      {order.orderStatus === "Shipped" &&
                        `Shipped on ${formatDate(order.shippedAt)}`}
                      {order.orderStatus === "Processing" &&
                        `Ordered on ${formatDate(order.createdAt)}`}
                      {order.orderStatus === "Delivered" &&
                        `Delivered on ${formatDate(order.deliveredAt)}`}
                      {!["Shipped", "Processing", "Delivered"].includes(
                        order.orderStatus
                      ) && "Status unknown"}
                    </p>
                  </div>

                  <FormControl fullWidth sx={{ marginTop: 1 }}>
                    <InputLabel id="order-status-select-label">
                      Status
                    </InputLabel>
                    <Select
                      labelId="order-status-select-label"
                      id="order-status-select"
                      value={status}
                      label="Status"
                      onChange={(e) => setStatus(e.target.value)}
                    >
                      {order.orderStatus === "Shipped" && (
                        <MenuItem value={"Delivered"}>Delivered</MenuItem>
                      )}
                      {order.orderStatus === "Processing" && (
                        <MenuItem value={"Shipped"}>Shipped</MenuItem>
                      )}
                      {order.orderStatus === "Delivered" && (
                        <MenuItem value={"Delivered"}>Delivered</MenuItem>
                      )}
                    </Select>
                  </FormControl>
                  {order.orderStatus === "Processing" && (
                    <>
                      <FormControl fullWidth sx={{ marginTop: 1 }}>
                        <InputLabel id="order-courier-select-label">
                          Courier Company
                        </InputLabel>
                        <Select
                          labelId="order-courier-select-label"
                          id="order-courier-select"
                          value={courierDetail.companyName}
                          label="Courier Company"
                          onChange={(e) =>
                            setCourierDetail((prev) => ({
                              ...prev,
                              companyName: e.target.value,
                            }))
                          }
                        >
                          {courierCompany &&
                            courierCompany.map((name, i) => (
                              <MenuItem key={i} value={name}>
                                {name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                      <TextField
                        className={"w-full"}
                        label="Tracking Id"
                        type="text"
                        variant="outlined"
                        size="small"
                        value={courierDetail.trackingId}
                        autoCapitalize="true"
                        onChange={(e) =>
                          setCourierDetail((prev) => ({
                            ...prev,
                            trackingId: e.target.value,
                          }))
                        }
                      />
                    </>
                  )}
                  <button
                    type="submit"
                    className="bg-primary-orange p-2.5 text-white font-medium rounded shadow hover:shadow-lg"
                  >
                    Update
                  </button>
                </form>
              </div>

              {order.orderItems &&
                order.orderItems.map((item) => {
                  const { _id, image, name, price, quantity } = item;

                  return (
                    <div
                      className="flex flex-col sm:flex-row min-w-full shadow-lg rounded-lg bg-white px-2 py-5"
                      key={_id}
                    >
                      <div className="flex flex-col sm:flex-row sm:w-1/2 gap-1">
                        <div className="w-full sm:w-32 h-24">
                          <img
                            draggable="false"
                            className="h-full w-full object-contain"
                            src={image}
                            alt={name}
                          />
                        </div>
                        <div className="flex flex-col gap-1 overflow-hidden">
                          <p className="text-sm">
                            {name.length > 45
                              ? `${name.substring(0, 45)}...`
                              : name}
                          </p>
                          <p className="text-xs text-gray-600 mt-2">
                            Quantity: {quantity}
                          </p>
                          <p className="text-xs text-gray-600">
                            Price: Rs{price.toLocaleString()}
                          </p>
                          <span className="font-medium">
                            Total: Rs{(quantity * price).toLocaleString()}
                          </span>
                        </div>
                      </div>

                      <div className="flex flex-col w-full sm:w-1/2">
                        <h3 className="font-medium sm:text-center">
                          Order Status
                        </h3>
                        <TrackStepper
                          orderOn={order.createdAt}
                          shippedAt={order.shippedAt}
                          deliveredAt={order.deliveredAt}
                          activeStep={
                            order.orderStatus === "Delivered"
                              ? 2
                              : order.orderStatus === "Shipped"
                              ? 1
                              : 0
                          }
                        />
                      </div>
                    </div>
                  );
                })}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default UpdateOrder;
