import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { clearErrors, getCategories } from "../../actions/caetgoryAction";
import { useSnackbar } from "notistack";

const MinCategory = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const { categories, error, loading } = useSelector(
    (state) => state.categories
  );

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error, { variant: "error" });
      dispatch(clearErrors());
    }

    dispatch(getCategories());
  }, [dispatch, error, enqueueSnackbar]);

  return (
    <section className="hidden sm:block bg-white w-full px-2 sm:px-12 overflow-hidden border-b mt-14">
      <div className="flex items-center justify-start p-0.5">
        {!loading &&
          categories.map((el, i) => (
            <Link
              to={`/products?category=${el.slug}`}
              key={i}
              className="text-sm p-2 text-gray-800 font-medium hover:text-primary-blue flex items-center gap-0.5 group"
            >
              {el.name}{" "}
            </Link>
          ))}
      </div>
    </section>
  );
};

export default MinCategory;
