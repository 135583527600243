import axios from "axios";
import {
  ALL_Carousel_REQUEST,
  ALL_Carousel_SUCCESS,
  ALL_Carousel_FAIL,
  NEW_Carousel_REQUEST,
  NEW_Carousel_SUCCESS,
  NEW_Carousel_RESET,
  NEW_Carousel_FAIL,
  DELETE_Carousel_REQUEST,
  DELETE_Carousel_SUCCESS,
  DELETE_Carousel_RESET,
  DELETE_Carousel_FAIL,
  CLEAR_ERRORS,
} from "../constants/carouselConstant";

// Get All Carousel
export const getCarousel = () => async (dispatch) => {
  try {
    dispatch({ type: ALL_Carousel_REQUEST });

    let url = `/api/v1/carousel`;

    const { data } = await axios.get(url);
    dispatch({
      type: ALL_Carousel_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ALL_Carousel_FAIL,
      payload: error.response.data.message,
    });
  }
};

// New Product ---ADMIN
export const createCarousel = (CarouselData) => async (dispatch) => {
  try {
    dispatch({ type: NEW_Carousel_REQUEST });
    const config = { header: { "Content-Type": "application/json" } };
    const { data } = await axios.post(
      "/api/v1/admin/carousel",
      CarouselData,
      config
    );

    dispatch({
      type: NEW_Carousel_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: NEW_Carousel_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Delete Carousel ---ADMIN
export const deleteCarousel = (id) => async (dispatch) => {
  console.log("🚀 ~ deleteCarousel ~ id:", id);
  try {
    dispatch({ type: DELETE_Carousel_REQUEST });
    const { data } = await axios.delete(`/api/v1/admin/carousel/${id}`);

    dispatch({
      type: DELETE_Carousel_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: DELETE_Carousel_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Clear All Errors
export const clearErrors = () => (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
};
