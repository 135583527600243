// import { useGoogleLoginMutation } from "../../api/authApiSlice";
import { GoogleLogin } from "@react-oauth/google";
import { useSnackbar } from "notistack";

const GoogleLoginComponent = ({ loginFunction }) => {

  const { enqueueSnackbar } = useSnackbar();
  

  return (
    <GoogleLogin
      onSuccess={(credentialResponse) => {
        loginFunction(credentialResponse);
      }}
      onError={() => {
       enqueueSnackbar("Something Went Wrong!", { variant: "error" });
      }}
    />
  );
};

export default GoogleLoginComponent;
