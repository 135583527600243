import React from "react";
import PackingSlip from "./PackingSlip";

const OrderDetail = ({ order }) => {
  return (
    <div className="flex flex-col gap-3 my-8 mx-10">
      <div className="flex flex-col gap-3" id="print-area">
        <h2 className=" text-lg">
          <span className="font-bold">Order Id </span>
          {order._id}
        </h2>
        <div className="flex gap-2 text-sm">
          <p className="font-medium">Name</p>
          <p>{order.user.name}</p>
        </div>
        <div className="flex gap-2 text-sm">
          <p className="font-medium">Email</p>
          <p>{order.user.email}</p>
        </div>
        <div className="flex gap-2 text-sm">
          <p className="font-medium">Phone Number</p>
          <p>{order.shippingInfo.phoneNo}</p>
        </div>
        <h3 className="font-medium text-lg">Delivery Address</h3>

        <p className="text-sm">{`${order.shippingInfo.address}, ${order.shippingInfo.city}, ${order.shippingInfo.state} - ${order.shippingInfo.pincode}`}</p>

        <h3 className="font-medium text-lg">Courier Detail</h3>
        {order.orderStatus !== "Processing" ? (
          <>
            <div className="flex gap-2 text-sm">
              <p className="font-medium">Company</p>
              <p>{order.courierDetail.companyName}</p>
            </div>
            <div className="flex gap-2 text-sm">
              <p className="font-medium">Tracking Id</p>
              <p>{order.courierDetail.trackingId}</p>
            </div>
          </>
        ) : (
          <p>Not Yet Shipped</p>
        )}
      </div>
      <PackingSlip data={order} />
    </div>
  );
};

export default OrderDetail;
