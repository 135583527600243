
import { Link } from 'react-router-dom';
import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {clearErrors, getCategories} from "../../actions/caetgoryAction";
import {useSnackbar} from "notistack";


const Categories = () => {


    const dispatch = useDispatch()
    const { categories,error } = useSelector((state) => state.categories);
    const { enqueueSnackbar } = useSnackbar();


    useEffect(() => {

        if (error) {
            enqueueSnackbar(error, { variant: "error" });
            dispatch(clearErrors());
        }

        dispatch(getCategories())
    }, [dispatch, error,enqueueSnackbar]);

    return (
        <section className="hidden sm:block bg-white mt-10 mb-4 min-w-full px-12 py-1 shadow overflow-hidden">

            <div className="flex items-center justify-center mt-4">
                {/*{loading && <BackdropLoader />}*/}
                {categories && categories.map((item, i) => (
                    <Link to={`/products?category=${item.slug}`} className="flex flex-col gap-1 items-center p-2 group" key={i}>
                        <div className="h-16 w-16">
                            <img draggable="false" className="h-full w-full rounded-full object-contain" src={item.image} alt={item.name} />
                        </div>
                        <span className="text-sm text-gray-800 font-medium group-hover:text-primary-blue">{item.name}</span>
                    </Link>
                ))}

            </div>
        </section>
    );
};

export default Categories;
