// import { useEffect, useState } from "react";
// import Sidebar from "./Sidebar";
// import { useDispatch, useSelector } from "react-redux";
// import { Link, useNavigate } from "react-router-dom";
// import Loader from "../Layouts/Loader";
// import MinCategory from "../Layouts/MinCategory";
// import MetaData from "../Layouts/MetaData";
// import PhoneInput from "react-phone-input-2";
// import "react-phone-input-2/lib/style.css";
// import { updateProfile } from "../../actions/userAction";

// const Account = () => {
//   const dispatch = useDispatch();
//   const navigate = useNavigate();

//   const { user, loading, isAuthenticated } = useSelector((state) => state.user);

//   useEffect(() => {
//     if (!isAuthenticated) {
//       navigate("/login");
//     }
//   }, [isAuthenticated, navigate]);

//   const getLastName = () => {
//     const nameArray = user?.name.split(" ");
//     return nameArray[nameArray.length - 1];
//   };

//   return (
//     <>
//       <MetaData title="My Profile" />

//       {loading ? (
//         <Loader />
//       ) : (
//         <>
//           <MinCategory />
//           <main className="w-full mt-12 sm:mt-0">
//             <div className="flex gap-3.5 sm:w-11/12 sm:mt-4 m-auto mb-7">
//               <Sidebar activeTab={"profile"} />

//               <div className="flex-1 overflow-hidden shadow bg-white">
//                 <div className="flex flex-col gap-12 m-4 sm:mx-8 sm:my-6">
//                   <div className="flex flex-col gap-5 items-start">
//                     <span className="font-medium text-lg">
//                       Personal Information{" "}
//                       <Link
//                         to="/account/update"
//                         className="text-sm text-primary-blue font-medium ml-8 cursor-pointer"
//                       >
//                         Edit
//                       </Link>
//                     </span>

//                     <div
//                       className="flex flex-col sm:flex-row items-center gap-3"
//                       id="personalInputs"
//                     >
//                       <div className="flex flex-col gap-0.5 w-64 px-3 py-1.5 rounded-sm border inputs cursor-not-allowed bg-gray-100 focus-within:border-primary-blue">
//                         <label className="text-xs text-gray-500">
//                           First Name
//                         </label>
//                         <input
//                           type="text"
//                           value={user?.name.split(" ", 1)}
//                           className="text-sm outline-none border-none cursor-not-allowed text-gray-500"
//                           disabled
//                         />
//                       </div>
//                       <div className="flex flex-col gap-0.5 w-64 px-3 py-1.5 rounded-sm border inputs cursor-not-allowed bg-gray-100 focus-within:border-primary-blue">
//                         <label className="text-xs text-gray-500">
//                           Last Name
//                         </label>
//                         <input
//                           type="text"
//                           value={getLastName()}
//                           className="text-sm outline-none border-none cursor-not-allowed text-gray-500"
//                           disabled
//                         />
//                       </div>
//                     </div>

//                     <div className="flex flex-col gap-2">
//                       <h2 className="text-sm">Your Gender</h2>
//                       <div className="flex items-center gap-8" id="radioInput">
//                         <div className="flex items-center gap-4 inputs text-gray-500 cursor-not-allowed">
//                           <input
//                             type="radio"
//                             name="gender"
//                             checked={user?.gender === "male"}
//                             id="male"
//                             className="h-4 w-4 cursor-not-allowed"
//                             disabled
//                           />
//                           <label htmlFor="male" className="cursor-not-allowed">
//                             Male
//                           </label>
//                         </div>
//                         <div className="flex items-center gap-4 inputs text-gray-500 cursor-not-allowed">
//                           <input
//                             type="radio"
//                             name="gender"
//                             checked={user?.gender === "female"}
//                             id="female"
//                             className="h-4 w-4 cursor-not-allowed"
//                             disabled
//                           />
//                           <label
//                             htmlFor="female"
//                             className="cursor-not-allowed"
//                           >
//                             Female
//                           </label>
//                         </div>
//                       </div>
//                     </div>
//                   </div>

//                   <div className="flex flex-col gap-5 items-start">
//                     <span className="font-medium text-lg">
//                       Email Address
//                       <Link
//                         to="/password/update"
//                         className="text-sm text-primary-blue font-medium ml-3 sm:ml-8"
//                       >
//                         Change Password
//                       </Link>
//                     </span>

//                     <div className="flex items-center gap-3">
//                       <div className="flex flex-col gap-0.5 sm:w-64 px-3 py-1.5 rounded-sm border bg-gray-100 cursor-not-allowed focus-within:border-primary-blue">
//                         <label className="text-xs text-gray-500">
//                           Email Address
//                         </label>
//                         <input
//                           type="email"
//                           value={user?.email}
//                           className="text-sm outline-none border-none cursor-not-allowed text-gray-500"
//                           disabled
//                         />
//                       </div>
//                     </div>
//                   </div>

//                   <div className="flex flex-col items-start">
//                     <span className="font-medium text-lg flex items-center gap-2">
//                       Mobile Number
//                     </span>

//                     <div className="flex items-center gap-3 w-full max-w-md">
//                       <div className="flex flex-col gap-0.5 w-full px-3 py-2 rounded-sm border bg-gray-100 focus-within:border-primary-blue">
//                         <label className="text-xs text-gray-500">
//                           Mobile Number
//                         </label>
//                         <PhoneInput
//                           international
//                           country={"pk"}
//                           value={user?.number}
//                           className="text-sm outline-none border-none text-gray-500 w-full"
//                           disabled={true}
//                         />
//                       </div>
//                     </div>
//                   </div>

//                   <div className="flex flex-col gap-4 mt-4">
//                     <span className="font-medium text-lg mb-2">FAQS</span>
//                     <h4 className="text-sm font-medium">
//                       What happens when I update my email address (or mobile
//                       number)?
//                     </h4>
//                     <p className="text-sm">
//                       Your login email id (or mobile number) changes, likewise.
//                       You'll receive all your account related communication on
//                       your updated email address (or mobile number).
//                     </p>

//                     <h4 className="text-sm font-medium">
//                       When will my powermart account be updated with the new
//                       email address (or mobile number)?
//                     </h4>
//                     <p className="text-sm">
//                       It happens as soon as you confirm the verification code
//                       sent to your email (or mobile) and save the changes.
//                     </p>

//                     <h4 className="text-sm font-medium">
//                       What happens to my existing powermart account when I
//                       update my email address (or mobile number)?
//                     </h4>
//                     <p className="text-sm">
//                       Updating your email address (or mobile number) doesn't
//                       invalidate your account. Your account remains fully
//                       functional. You'll continue seeing your Order history,
//                       saved information and personal details.
//                     </p>

//                     <h4 className="text-sm font-medium">
//                       Does my Seller account get affected when I update my email
//                       address?
//                     </h4>
//                     <p className="text-sm">
//                       powermart has a 'single sign-on' policy. Any changes will
//                       reflect in your Seller account also.
//                     </p>
//                   </div>

//                   <Link
//                     className="text-sm text-primary-blue font-medium"
//                     to="/"
//                   >
//                     Deactivate Account
//                   </Link>
//                 </div>

//                 <img
//                   draggable="false"
//                   className="w-full object-contain"
//                   src="https://static-assets-web.flixcart.com/www/linchpin/fk-cp-zion/img/myProfileFooter_4e9fe2.png"
//                   alt="footer"
//                 />
//               </div>
//             </div>
//           </main>
//         </>
//       )}
//     </>
//   );
// };

// export default Account;





import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../Layouts/Loader";
import MinCategory from "../Layouts/MinCategory";
import MetaData from "../Layouts/MetaData";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Sidebar from "./Sidebar";
// import { ReactComponent as AvatarIcon } from "../assets/avatar-icon.svg"; // Ensure correct path

const Account = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, loading, isAuthenticated } = useSelector((state) => state.user);

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/login");
    }
  }, [isAuthenticated, navigate]);

  const getLastName = () => {
    const nameArray = user?.name.split(" ");
    return nameArray[nameArray.length - 1];
  };

  return (
    <>
      <MetaData title="My Profile" />

      {loading ? (
        <Loader />
      ) : (
        <>
          <MinCategory />
          <main className="bg-gray-50 min-h-screen py-8 px-4 sm:px-6 lg:px-8">
            <div className="max-w-7xl mx-auto">
              <div className="flex flex-col lg:flex-row gap-8">
                {/* Sidebar */}
                {/* <aside className="lg:w-1/4 bg-white shadow-md rounded-lg">
                  <nav className="p-4">
                    <ul className="space-y-2">
                      <li>
                        <Link to="/account" className="text-gray-800 hover:text-primary-blue">
                          Profile Overview
                        </Link>
                      </li>
                      <li>
                        <Link to="/account/update" className="text-gray-800 hover:text-primary-blue">
                          Edit Profile
                        </Link>
                      </li>
                      <li>
                        <Link to="/password/update" className="text-gray-800 hover:text-primary-blue">
                          Change Password
                        </Link>
                      </li>
                      <li>
                        <Link to="/" className="text-gray-800 hover:text-primary-blue">
                          Deactivate Account
                        </Link>
                      </li>
                    </ul>
                  </nav>
                </aside> */}
                <Sidebar/>

                {/* Main Content */}
                <div className="lg:flex-1 bg-white shadow-md rounded-lg p-6">
                  <div className="flex items-center gap-4 mb-6">
                    {/* <AvatarIcon className="w-16 h-16 text-gray-400" /> */}
                    <div>
                      <h1 className="text-2xl font-semibold text-gray-800">{user?.name}</h1>
                      <p className="text-gray-600">{user?.email}</p>
                    </div>
                  </div>

                  <section className="mb-6">
                    <h2 className="text-xl font-medium text-gray-800 mb-4">Personal Information</h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                      <div className="bg-gray-100 p-4 rounded-lg">
                        <label className="block text-gray-600 mb-2">First Name</label>
                        <input
                          type="text"
                          value={user?.name.split(" ")[0]}
                          className="w-full p-2 border border-gray-300 rounded-lg bg-white"
                          disabled
                        />
                      </div>
                      <div className="bg-gray-100 p-4 rounded-lg">
                        <label className="block text-gray-600 mb-2">Last Name</label>
                        <input
                          type="text"
                          value={getLastName()}
                          className="w-full p-2 border border-gray-300 rounded-lg bg-white"
                          disabled
                        />
                      </div>
                    </div>
                  </section>

                  <section className="mb-6">
                    <h2 className="text-xl font-medium text-gray-800 mb-4">Email Address</h2>
                    <div className="bg-gray-100 p-4 rounded-lg">
                      <label className="block text-gray-600 mb-2">Email Address</label>
                      <input
                        type="email"
                        value={user?.email}
                        className="w-full p-2 border border-gray-300 rounded-lg bg-white"
                        disabled
                      />
                    </div>
                  </section>

                  <section className="mb-6">
                    <h2 className="text-xl font-medium text-gray-800 mb-4">Mobile Number</h2>
                    <div className="bg-gray-100 p-4 rounded-lg">
                      <label className="block text-gray-600 mb-2">Mobile Number</label>
                      <PhoneInput
                        international
                        country={"pk"}
                        value={user?.number}
                        className="w-full border border-gray-300 rounded-lg"
                        disabled
                      />
                    </div>
                  </section>

                  <section className="mb-6">
                    <h2 className="text-xl font-medium text-gray-800 mb-4">FAQs</h2>
                    <div className="space-y-4">
                      <div>
                        <h4 className="text-lg font-semibold text-gray-700">What happens when I update my email address (or mobile number)?</h4>
                        <p className="text-gray-600">
                          Your login email id (or mobile number) changes, likewise. You'll receive all your account related communication on your updated email address (or mobile number).
                        </p>
                      </div>
                      <div>
                        <h4 className="text-lg font-semibold text-gray-700">When will my account be updated with the new email address (or mobile number)?</h4>
                        <p className="text-gray-600">
                          It happens as soon as you confirm the verification code sent to your email (or mobile) and save the changes.
                        </p>
                      </div>
                      <div>
                        <h4 className="text-lg font-semibold text-gray-700">What happens to my existing account when I update my email address (or mobile number)?</h4>
                        <p className="text-gray-600">
                          Updating your email address (or mobile number) doesn't invalidate your account. Your account remains fully functional. You'll continue seeing your Order history, saved information and personal details.
                        </p>
                      </div>
                      <div>
                        <h4 className="text-lg font-semibold text-gray-700">Does my Seller account get affected when I update my email address?</h4>
                        <p className="text-gray-600">
                          Our platform has a 'single sign-on' policy. Any changes will reflect in your Seller account also.
                        </p>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </main>
        </>
      )}
    </>
  );
};

export default Account;
