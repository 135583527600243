import React, { useEffect } from "react";

const PriceSidebar = ({ cartItems , setFinalPrice=null }) => {

  // Calculate the maximum delivery charges
  const maxDeliveryCharges = cartItems.reduce((max, item) => {
    const deliveryCharges = item?.deliveryCharges || 0;
    return deliveryCharges > max ? deliveryCharges : max;
  }, 0);

  // Calculate the total discount
  const totalDiscount = cartItems.reduce(
    (sum, item) =>
      Math.abs(
        sum + (item.cuttedPrice * item.quantity - item.price * item.quantity)
      ),
    0
  );

  // Calculate the sum of actual item prices
  const totalPrice = cartItems.reduce(
    (sum, item) => sum + item.price * item.quantity,
    0
  );

  // Calculate the final total price
  const finalTotalPrice = totalPrice + maxDeliveryCharges;
  useEffect(()=>{
    if(setFinalPrice){
        setFinalPrice(finalTotalPrice)
      }
  },[setFinalPrice,finalTotalPrice])

  return (
    <div className="flex sticky top-16 sm:h-screen flex-col sm:w-4/12 sm:px-1">
      {/* <!-- nav tiles --> */}
      <div className="flex flex-col bg-white rounded-sm shadow">
        <h1 className="px-6 py-3 border-b font-medium text-gray-500">
          PRICE DETAILS
        </h1>

        <div className="flex flex-col gap-4 p-6 pb-3">
          <p className="flex justify-between">
            SubTotal ({cartItems.length} item{cartItems.length > 1 ? "s" : ""}){" "}
            <span>
              Rs
              {totalPrice.toLocaleString()}
            </span>
          </p>
          {/* <p className="flex justify-between">
            Discount{" "}
            <span className="text-primary-green">
              - Rs
              {totalDiscount.toLocaleString()}
            </span>
          </p> */}
          <p className="flex justify-between">
            Delivery Charges{" "}
            <span className="text-primary-green">
              {maxDeliveryCharges > 0
                ? `Rs ${maxDeliveryCharges.toLocaleString()}`
                : "Free"}
            </span>
          </p>

          <div className="border border-dashed"></div>
          <p className="flex justify-between text-lg font-medium">
            Total Amount{" "}
            <span>
              Rs
              {finalTotalPrice.toLocaleString()}
            </span>
          </p>
          <div className="border border-dashed"></div>

          <p className="font-medium text-primary-green">
            You will save Rs
            {totalDiscount.toLocaleString()} on this order
          </p>
        </div>
      </div>
      {/* <!-- nav tiles --> */}
    </div>
  );
};

export default PriceSidebar;
