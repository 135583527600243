import { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import {clearErrors, deleteCategory, getCategories} from '../../actions/caetgoryAction';
import { DELETE_CATEGORY_RESET } from '../../constants/categoryConstant';
import Actions from './Actions';
import MetaData from '../Layouts/MetaData';
import BackdropLoader from '../Layouts/BackdropLoader';
import NewCategory from "./NewCategory";

const Category = () => {

    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const { categories,error } = useSelector((state) => state.categories);

    const { loading, isDeleted, error: deleteError } = useSelector((state) => state.category);

    useEffect(() => {
        if (error) {
            enqueueSnackbar(error, { variant: "error" });
            dispatch(clearErrors());
        }
        if (deleteError) {
            enqueueSnackbar(deleteError, { variant: "error" });
            dispatch(clearErrors());
        }
        if (isDeleted) {
            enqueueSnackbar("Product Deleted Successfully", { variant: "success" });
            dispatch({ type: DELETE_CATEGORY_RESET });
        }
        dispatch(getCategories());
    }, [dispatch, error, deleteError, isDeleted, enqueueSnackbar]);

    const deleteProductHandler = (id) => {
        dispatch(deleteCategory(id));
    }

    const columns = [
        {
            field: "id",
            headerName: "Category Id",
            minWidth: 100,
            flex: 0.5,
        },
        {
            field: "name",
            headerName: "Name",
            minWidth: 100,
            flex: 1,
            renderCell: (params) => {
                return (
                    <div className="flex items-center gap-2">
                        <div className="w-10 h-10 rounded-full">
                            <img draggable="false" src={params.row.image} alt={params.row.name} className="w-full h-full rounded-full object-cover" />
                        </div>
                        {params.row.name}
                    </div>
                )
            },
        },
       
        {
            field: "productCount",
            headerName: "Product Count",
            type: "number",
            headerAlign: "left",
            align: "left",
            minWidth: 120,
            flex: 0.1,
            renderCell: (params) => {
                return (
                    <>
                        <span className="">{params.row.productCount}</span>
                    </>
                )
            },
        },

        {
            field: "actions",
            headerName: "Actions",
            minWidth: 110,
            flex: 0.3,
            type: "number",
            sortable: false,
            renderCell: (params) => {
                return (
                    <Actions editRoute={"category"} deleteHandler={deleteProductHandler} id={params.row.id} />
                );
            },
        },
    ];

    const rows = [];

    categories && categories.forEach((item) => {
        rows.unshift({
            id: item._id,
            name: item.name,
            image: item.image,
            productCount: item.productCount,
           
        });
    });

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        dispatch(getCategories());
    }

    return (
        <>
            <MetaData title="Admin Products | Power Mart" />

            {loading && <BackdropLoader />}

            <div className="flex justify-between items-center">
                <h1 className="text-lg font-medium uppercase">Category</h1>
                <button onClick={handleOpen} className="py-2 px-4 rounded shadow font-medium text-white bg-primary-blue hover:shadow-lg">New Category</button>
            </div>
            <div className="bg-white rounded-xl shadow-lg w-full">

                <DataGrid
                    rows={rows}
                    columns={columns}
                    pageSize={10}
                    disableSelectIconOnClick
                    sx={{
                        boxShadow: 0,
                        border: 0,
                         minHeight:600
                    }}
                />
            </div>


            {open && <NewCategory handleClose={handleClose} open={open} categories={categories}/>}
        </>
    );
};

export default Category;