import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NEW_CATEGORY_RESET } from '../../constants/categoryConstant';
import { createCategory, clearErrors, getCategories } from '../../actions/caetgoryAction';
import Loading from './Loading';
import TextField from '@mui/material/TextField';
import MetaData from '../Layouts/MetaData';
import BackdropLoader from '../Layouts/BackdropLoader';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import ImageIcon from "@mui/icons-material/Image";

const AddCategory = ({open,handleClose, categories}) => {

    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const { loading, success, error } = useSelector((state) => state.newCategory);

    const [name, setName] = useState("");
    const [logo, setLogo] = useState("");
    const [logoPreview, setLogoPreview] = useState("");

    const addCategorySubmitHandler = (e) => {
        e.preventDefault();



        const formData = new FormData();
        formData.set("name", name);
        formData.set("image", logo);
        console.log("form data",formData)
        dispatch(createCategory(formData));
        dispatch(getCategories());
        handleClose()
    }

    useEffect(() => {
        if (error) {
            enqueueSnackbar(error, { variant: "error" });
            dispatch(clearErrors());
        }
        if (success) {
            enqueueSnackbar("Category Created", { variant: "success" });
            dispatch({ type: NEW_CATEGORY_RESET });
        }
    }, [dispatch, error, success,  enqueueSnackbar]);



    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };



    const handleLogoChange = (e) => {
        const reader = new FileReader();

        reader.onload = () => {
            if (reader.readyState === 2) {
                setLogoPreview(reader.result);
                setLogo(reader.result);
            }
        };

        reader.readAsDataURL(e.target.files[0]);
    }

    return (
        <>
            <MetaData title="Admin: New Category | Power Mart" />

            {loading && <BackdropLoader />}

                <Modal
                    aria-labelledby="spring-modal-title"
                    aria-describedby="spring-modal-description"
                    open={open}
                    onClose={handleClose}
                    closeAfterTransition
                    slots={{ backdrop: Backdrop }}

                >
                    <Box sx={style}>
                        <h2 className="text-center text-2xl font-medium mt-6 text-gray-800">Add Category</h2>

                        <form
                            encType="multipart/form-data"
                            onSubmit={addCategorySubmitHandler}
                            className="p-5 sm:p-10"
                        >
                            <div className="flex flex-col gap-3 items-start">

                                {/* <!-- input container column --> */}
                                <div
                                    className="flex flex-col w-full justify-between sm:flex-col gap-3 items-center">
                                    <TextField
                                        fullWidth
                                        label="Name"
                                        name="name"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        required
                                    />

                                </div>
                                {/* <!-- input container column --> */}


                                <div
                                    className="flex flex-col w-full justify-between sm:flex-row gap-3 items-center">
                                    <div className="w-24 h-10 flex items-center justify-center rounded-lg">
                                        {!logoPreview ? <ImageIcon/> :
                                            <img draggable="false" src={logoPreview} alt="Brand Logo"
                                                 className="w-full h-full object-contain"/>
                                        }
                                    </div>
                                    <label
                                        className="rounded bg-gray-400 text-center cursor-pointer text-white py-2 px-2.5 shadow hover:shadow-lg">
                                        <input
                                            type="file"
                                            name="logo"
                                            accept="image/*"
                                            onChange={handleLogoChange}
                                            className="hidden"
                                        />
                                        Choose Logo
                                    </label>
                                </div>

                                <button type="submit"
                                        className="text-white py-3 w-full bg-primary-orange shadow hover:shadow-lg rounded-sm font-medium">Add
                                </button>
                                <button
                                    className="hover:bg-gray-100 text-primary-blue text-center py-3 w-full shadow border rounded-sm font-medium"
                                    onClick={handleClose}>Cancel
                                </button>
                            </div>

                        </form>
                    </Box>
                </Modal>
        </>
    );
};

export default AddCategory;
