import { useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import {
  clearErrors,
  deleteOrder,
  getAllOrders,
} from "../../actions/orderAction";
import { DELETE_ORDER_RESET } from "../../constants/orderConstants";
import Actions from "./Actions";
import { formatDate } from "../../utils/functions";
import MetaData from "../Layouts/MetaData";
import BackdropLoader from "../Layouts/BackdropLoader";

const OrderTable = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const { orders, error } = useSelector((state) => state.allOrders);
  // console.log("all orders at OrderTable in admin: " , orders);
  const {
    loading,
    isDeleted,
    error: deleteError,
  } = useSelector((state) => state.order);

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error, { variant: "error" });
      dispatch(clearErrors());
    }
    if (deleteError) {
      enqueueSnackbar(deleteError, { variant: "error" });
      dispatch(clearErrors());
    }
    if (isDeleted) {
      enqueueSnackbar("Deleted Successfully", { variant: "success" });
      dispatch({ type: DELETE_ORDER_RESET });
    }
    dispatch(getAllOrders());
  }, [dispatch, error, deleteError, isDeleted, enqueueSnackbar]);

  const deleteOrderHandler = (id) => {
    dispatch(deleteOrder(id));
  };

  const columns = [
    {
      field: "id",
      headerName: "Order ID",
      minWidth: 200,
      flex: 1,
    },
    // {
    //     field: "status",
    //     headerName: "Status",
    //     minWidth: 150,
    //     flex: 0.2,
    //     renderCell: (params) => {
    //         return (
    //             <>
    //                 {
    //                     params.row.status === "Delivered" ? (
    //                         <span className="text-sm bg-green-100 p-1 px-2 font-medium rounded-full text-green-800">{params.row.status}</span>
    //                     ) : params.row.status === "Shipped" ? (
    //                         <span className="text-sm bg-yellow-100 p-1 px-2 font-medium rounded-full text-yellow-800">{params.row.status}</span>
    //                     ) : (
    //                         <span className="text-sm bg-purple-100 p-1 px-2 font-medium rounded-full text-purple-800">{params.row.status}</span>
    //                     )
    //                 }
    //             </>
    //         )
    //     },
    // },
    {
      field: "status",
      headerName: "Status",
      minWidth: 150,
      flex: 0.2,
      renderCell: (params) => {
        const statusClassMap = {
          Delivered: "text-green-800 bg-green-100",
          Shipped: "text-yellow-800 bg-yellow-100",
          Processing: "text-blue-800 bg-blue-100", // Example additional status
          // Add other statuses here
        };

        const statusClass =
          statusClassMap[params.row.status] || "text-red-800 bg-red-100"; // Class for unknown status

        return (
          <span
            className={`text-sm p-1 px-2 font-medium rounded-full ${statusClass}`}
          >
            {params.row.status || "Unknown Status"}
          </span>
        );
      },
    },
    {
      field: "itemsQty",
      headerName: "Items Qty",
      type: "number",
      minWidth: 100,
      flex: 0.2,
    },
    {
      field: "amount",
      headerName: "Amount",
      type: "number",
      minWidth: 100,
      // flex: 0.2,
      headerAlign: "center",
      align: "start",
      renderCell: (params) => {
        return <span>Rs {params.row.amount.toLocaleString()}</span>;
      },
    },
    {
      field: "deliveryCharges",
      headerName: "DC",
      //   type: "string",
      minWidth: 100,
      flex: 0.2,
      renderCell: (params) => {
        // console.log("Delivery",params.row.deliveryCharges);
        return (
          // <span>Rs {params?.row?.deliveryCharges ? params?.row?.deliveryCharges : "Free" }</span>
          <span>
            Rs{" "}
            {params?.row?.deliveryCharges !== "Free"
              ? params?.row?.deliveryCharges
              : "Free"}
          </span>
        );
      },
    },
    {
      field: "orderOn",
      headerName: "Order On",
      type: "date",
      minWidth: 200,
      flex: 0.5,
    },
    {
      field: "actions",
      headerName: "Actions",
      minWidth: 100,
      flex: 0.3,
      type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <Actions
            editRoute={"order"}
            deleteHandler={deleteOrderHandler}
            id={params.row.id}
          />
        );
      },
    },
  ];

  const rows = [];

  orders &&
    orders.forEach((order) => {
      const totalDeliveryCharges = order.orderItems.reduce((total, item) => {
        const charge = Number(item.deliveryCharges) || 0; // Convert to number
        return total + charge;
      }, 0);
      const deliveryCharges =
        totalDeliveryCharges > 0 ? totalDeliveryCharges : "0";
      // console.log("OrderITems in OrderTable Admin: " , order?.orderItems[0].deliveryCharges);
      rows.unshift({
        id: order._id,
        itemsQty: order.orderItems.length,
        amount: order.totalPrice,
        // deliveryCharges: deliveryCharges ? deliveryCharges : 0,
        deliveryCharges: deliveryCharges,

        orderOn: formatDate(order.createdAt),
        status: order.orderStatus,
      });
    });

  return (
    <>
      <MetaData title="Admin Orders | Power Mart" />

      {loading && <BackdropLoader />}

      <h1 className="text-lg font-medium uppercase">orders</h1>
      <div className="bg-white rounded-xl shadow-lg w-full">
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={10}
          disableSelectIconOnClick
          sx={{
            boxShadow: 0,
            border: 0,
            minHeight: 600,
          }}
        />
      </div>
    </>
  );
};

export default OrderTable;
