import {
  ALL_Carousel_REQUEST,
  ALL_Carousel_SUCCESS,
  ALL_Carousel_FAIL,
  NEW_Carousel_REQUEST,
  NEW_Carousel_SUCCESS,
  NEW_Carousel_RESET,
  NEW_Carousel_FAIL,
  DELETE_Carousel_REQUEST,
  DELETE_Carousel_SUCCESS,
  DELETE_Carousel_RESET,
  DELETE_Carousel_FAIL,
  CLEAR_ERRORS,
} from "../constants/carouselConstant";

export const carouselsReducer = (
  state = { carousels: [] },
  { type, payload }
) => {
  switch (type) {
    case ALL_Carousel_REQUEST:
      return {
        loading: true,
        carousels: [],
      };
    case ALL_Carousel_SUCCESS:
      return {
        loading: false,
        carousels: payload.carousel,
      };

    case ALL_Carousel_FAIL:
      return {
        loading: false,
        error: payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// New Carousel Reducer
export const newCarouselReducer = (
  state = { carousels: {} },
  { type, payload }
) => {
  switch (type) {
    case NEW_Carousel_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case NEW_Carousel_SUCCESS:
      return {
        loading: false,
        success: payload.success,
        carousels: payload.carousel,
      };
    case NEW_Carousel_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case NEW_Carousel_RESET:
      return {
        ...state,
        success: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// New Carousel Reducer
export const CarouselReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case DELETE_Carousel_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case DELETE_Carousel_SUCCESS:
      return {
        ...state,
        loading: false,
        isDeleted: payload,
      };
    case DELETE_Carousel_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    case DELETE_Carousel_RESET:
      return {
        ...state,
        isDeleted: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
