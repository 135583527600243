import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PriceSidebar from "./PriceSidebar";
import Stepper from "./Stepper";
import { useSnackbar } from "notistack";
import { saveShippingInfo } from "../../actions/cartAction";
import { useNavigate } from "react-router-dom";
import MetaData from "../Layouts/MetaData";
import Cities from "../../assets/Cities_States.json";
const Shipping = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const { cartItems } = useSelector((state) => state.cart);
  // console.log("cartItems in shipping: " , cartItems);
  const { shippingInfo } = useSelector((state) => state.cart);

  const [address, setAddress] = useState(shippingInfo.address);
  const [city, setCity] = useState(shippingInfo.city);
  const [country, setCountry] = useState("Pakistan");
  const [state, setState] = useState(shippingInfo.state);
  const [pincode, setPincode] = useState(shippingInfo.pincode);
  const [phoneNo, setPhoneNo] = useState(shippingInfo.phoneNo);
  const [citites, SetCitites] = useState([]);
  const states = Object.keys(Cities);

  useEffect(() => {
    let isState = states.includes(state);
    if (isState) {
      SetCitites(Cities[state]);
    }
  }, [state]);

  const shippingSubmit = (e) => {
    e.preventDefault();

    if (phoneNo.length !== 11) {
      enqueueSnackbar("Invalid Phone Number", { variant: "error" });
      return;
    }
    dispatch(
      saveShippingInfo({ address, city, country, state, pincode, phoneNo })
    );
    navigate("/order/confirm");
  };

  return (
    <>
      <MetaData title="Power Mart: Shipping Details" />
      <main className="w-full mt-20">
        {/* <!-- row --> */}
        <div className="flex flex-col sm:flex-row gap-3.5 w-full sm:w-11/12 mt-0 sm:mt-4 m-auto sm:mb-7 overflow-hidden">
          {/* <!-- cart column --> */}
          <div className="flex-1">
            <Stepper activeStep={1}>
              <div className="w-full bg-white">
                <form
                  onSubmit={shippingSubmit}
                  autoComplete="off"
                  className="flex flex-col justify-start gap-3 w-full sm:w-3/4 mx-1 sm:mx-8 my-4"
                >
                  <div className="flex gap-6">
                    <FormControl fullWidth>
                      <InputLabel id="country-select">Country</InputLabel>
                      <Select
                        labelId="country-select"
                        id="country-select"
                        defaultValue={country}
                        disabled
                        label="Country"
                        // onChange={(e) => setCountry(e.target.value)}
                      >
                        <MenuItem value={"Pakistan"}>Pakistan</MenuItem>
                      </Select>
                    </FormControl>

                    <FormControl fullWidth disabled={country ? false : true}>
                      <InputLabel id="state-select">State</InputLabel>
                      <Select
                        labelId="state-select"
                        id="state-select"
                        value={state}
                        label="State"
                        onChange={(e) => setState(e.target.value)}
                        required
                      >
                        {states.map((item, index) => (
                          <MenuItem key={index} value={item}>
                            {item}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="flex gap-6">
                    <FormControl fullWidth disabled={state ? false : true}>
                      <InputLabel id="state-city">City</InputLabel>
                      <Select
                        labelId="state-city"
                        id="state-city"
                        value={city}
                        label="city"
                        onChange={(e) => setCity(e.target.value)}
                        required
                      >
                        {citites.length > 0 ? (
                          citites.map((item, index) => (
                            <MenuItem key={index} value={item}>
                              {item}
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem disabled>Select State</MenuItem>
                        )}
                      </Select>
                    </FormControl>
                    <TextField
                      label="Landmark (Optional)"
                      fullWidth
                      variant="outlined"
                    />
                  </div>
                  <TextField
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    fullWidth
                    label="Address"
                    variant="outlined"
                    required
                  />

                  <div className="flex gap-6">
                    <TextField
                      value={pincode}
                      onChange={(e) => setPincode(e.target.value)}
                      type="number"
                      label="Postal Code"
                      fullWidth
                      variant="outlined"
                      required
                    />
                    <TextField
                      value={phoneNo}
                      onChange={(e) => setPhoneNo(e.target.value)}
                      placeholder={"03001234567"}
                      type="number"
                      label="Phone No"
                      fullWidth
                      variant="outlined"
                      required
                    />
                  </div>
                  <button
                    type="submit"
                    className="bg-primary-orange w-full sm:w-1/3 my-2 py-3.5 text-sm font-medium text-white shadow hover:shadow-lg rounded-sm uppercase outline-none"
                  >
                    save and deliver here
                  </button>
                </form>
              </div>
            </Stepper>
          </div>

          <PriceSidebar cartItems={cartItems} />
        </div>
      </main>
    </>
  );
};

export default Shipping;
